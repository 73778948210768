import { useState } from 'react';
import './ConnectsWithUs.css';
import {ReactComponent as AttachFileIcon} from '../../Utils/img/attach-file.svg';
import EmailPopUp from '../../Utils/EmailPopUp/EmailPopUp';
import axiosInstance from '../../Utils/Axios/axiosInstance';
import Spinner from '../../Utils/Spinner/Spinner';

function ConnectsWithUs(){
    const [fileContent, setFile] = useState(null);
    const [emailModal, setEmailModal] = useState(false);
    const [projectDescription, setProjectDescription] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [formData, setFormData] = useState(new FormData());

    const handleSelectFileClick = () => {
        document.getElementById('fileInput').click();
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if(file) {
            setFile(file);
        }
    }

    const handleFormSubmit = async () => {
        if(name.length === 0) {
            alert("Please Enter Your Full Name")
        } else if (!/^[A-Za-z\s]+$/.test(name)) {
            alert('Name should only contain letters and spaces.');
        } else if(email.length === 0) {
            alert("Please Enter Your Working Email ID")
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            alert('Invalid email format.');
        } else {
            document.querySelector('.message-us').style.display="none";
            document.querySelector('.spinner').style.display="block";

            axiosInstance.post(`https://${process.env.REACT_APP_END_POINT}/send-otp`,
                    {
                        email: email
                    }
                )
                .then(response => {
                    if (response.status === 200) 
                    {
                        const updatedFormData = new FormData();
                        updatedFormData.append('name', name);
                        updatedFormData.append('email', email);
                        updatedFormData.append('phone', phone);
                        updatedFormData.append('projectDescription', projectDescription);
                        
                        if (fileContent) {
                            updatedFormData.append('fileData', fileContent);
                        }
                            
                        setFormData(updatedFormData);
                        setEmailModal(!emailModal);
                        
                        document.querySelector('.spinner').style.display="none";
                        document.querySelector('.message-us').style.display="block";
                    }
                });
        }
    }

    return(
        <div className='connect-container'>
            <div className='connect-form'>
                <div className='connect-left'>
                    <h1>Let's Connect</h1>
                    <p>Send us a message, and we'll quickly talk about your project with you.</p>
                    <textarea className='help-us' id ='connectwithus-input' value={projectDescription} onChange={(event) => setProjectDescription(event.target.value)} placeholder='How we can help you?'/>                    
                    
                    <div className='pdf-container' onClick={handleSelectFileClick}>
                        <AttachFileIcon className='attachfile-icon' />
                        <p>Attach File (pdf, txt or docx)</p>
                    </div>

                    <input placeholder='Attach file' type='file' id='fileInput' style={{display:'none'}} onChange={handleFileChange} autoComplete="off" required/>

                    {fileContent && (
                        <p style={{margin:'0', maxWidth:'400px'}}>Attached File: <b>{fileContent.name}</b></p>
                    )} 

                    <input onChange={(event) => setName(event.target.value)} value={name} id ='connectwithus-input' placeholder='Full Name *' autoComplete="off"/>

                    <div>
                        <input onChange={(event) => setEmail(event.target.value)} value={email} id ='connectwithus-input' placeholder='Work Email *' autoComplete="off"/>
                        <input onChange={(event) => setPhone(event.target.value)} value={phone} id ='connectwithus-input' placeholder='Work Phone' autoComplete="off"/>
                    </div>

                    <button className='message-us' onClick={() => handleFormSubmit()}>Message us</button>
                    <Spinner />
                </div>

                <div className='connect-right'>
                    <h1>What's Next?</h1>
                    <div id="connect-right-content-container"><div id="connect-right-content">1</div><p>Our team will analyze your requirements.</p></div>
                    <div id="connect-right-content-container"><div id="connect-right-content">2</div><p>You receive a clear plan for your project.</p></div>
                    <div id="connect-right-content-container"><div id="connect-right-content">3</div><p>We turn your ideas into reality, allowing you to focus on expanding your business.</p></div>
                </div>
            </div>

            {emailModal ? <EmailPopUp name={name} email={email} setEmailModal={() => setEmailModal(!emailModal)} formData={formData} /> : ''}
        </div>
    );
}
export default ConnectsWithUs;
