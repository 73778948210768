import { useEffect, useState } from 'react';
import './EmailPopUp.css'
import ReactDOM from 'react-dom';
import axios from 'axios';
import {ReactComponent as HandShake} from '../../Utils/img/handshake.svg'
import {ReactComponent as DOT} from '../../Utils/img/dot.svg'


function EmailPopUp({name, email, setEmailModal, formData}){
    const [otp, setOTP] = useState('');
    const [otpValidate, setOtpValidate] = useState(false);

    useEffect(() => {
        document.body.style.overflow="hidden";

        return () => document.body.style.overflow="auto";
    }, []);

    function verifyOTP() {
        if ( otp.length === 0) {
            alert("Please enter OTP")
        } else {
            axios.post(`https://${process.env.REACT_APP_END_POINT}/verify-otp`, 
                    {
                        email: email,
                        otp: otp
                    }
                )
                .then(response => {
                    if( response.status === 200) {
                        if(response.data) {
                            setOtpValidate(!otpValidate);
                            
                            function changeBackgroundColor() {
                                const dotClasses = document.querySelectorAll('.dot');
                                let index = 0; // Initialize index to 0
                            
                                const interval = setInterval(() => {
                                    dotClasses[(index + 2) % 3].setAttribute('fill', "black");
                            
                                    dotClasses[index % 3].setAttribute('fill', "#00ACC1");
                            
                                    index += 1;
                            
                                    if (index === 20) {
                                        clearInterval(interval);
                                    }
                                }, 500);
                            }    
                        
                        
                            setTimeout(() => { changeBackgroundColor();}, 1000);
                            setTimeout(() => { window.location.href='/service' }, 13000);
                            
                            if(!formData.entries().next().done) {
                                if([...formData.entries()].length >= 6) {
                                    axios.post(`https://${process.env.REACT_APP_END_POINT}/quotation`, 
                                        {
                                            "name":formData.get("name"),
                                            "email":formData.get("email"),
                                            "service":formData.get("service"),
                                            "option":formData.get("option"),
                                            "type":formData.get("type"),
                                            "startTime":formData.get("startTime")
                                        },
                                        {
                                            headers:{
                                                "Content-Type": "application/json",
                                            }
                                        }                          
                                    );
                                }
                                else{
                                    axios.post(`https://${process.env.REACT_APP_END_POINT}/upload-data`, 
                                        formData,
                                        {
                                            headers: {
                                                "Content-Type": "multipart/form-data",
                                            },                            
                                        }
                                    );
                                }
                            }
                        } else {
                            alert("Invalid OTP")
                        }
                    }
                })
                .catch(error => alert(error.response.data));                            
        }
    }
    
    return ReactDOM.createPortal(
        <div className='email-root-container'>
            <div className='email-container'>
                {!otpValidate ? <>
                    <div className='email-header'><h1>Conform OTP</h1><h1 className='close-email-verification-modal' onClick={setEmailModal}>X</h1></div>                
                    <div className='email-content'>
                        <h2>Hey, {name}</h2>
                        <p>We have sent you an OTP on <b>{email}</b>. Please verify it.</p>
                    </div>

                    <input id='otp-input' placeholder='Enter OTP' onChange={(event) => setOTP(event.target.value)} autoComplete='off'/>
                    <button className='verify-button' onClick={verifyOTP}>Verify OTP</button>
                    <button className='resend-button'>Resend OTP</button>      
                    </> : 
                    <div className='verified-otp'>
                        <div className='email-header'><h1>OTP Verified Successfully</h1></div>                
                        <p>We will reach out to you as soon as possible.</p>
                        <HandShake />

                        <p>Redirecting you to our service page <DOT className='dot-container'/><DOT className='dot-container'/><DOT className='dot-container'/></p>
                    </div>
                }           
            </div>
        </div>
    , document.querySelector('.modal-container'));
}
export default EmailPopUp;
