import { useEffect, useState } from 'react';
import './Price.css'
import EmailPopUp from '../../Utils/EmailPopUp/EmailPopUp';
import axiosInstance from '../../Utils/Axios/axiosInstance';
import Spinner from '../../Utils/Spinner/Spinner';

function Price(){
    const [serviceClass, setserviceClass] = useState('');
    const [optionClass, setOptionClass] = useState('');
    const [typeClass, setTypeClass] = useState('');
    const [startClass, setStartClass] = useState('');
    const [step, setStep] = useState(1);
    const [emailModal, setEmailModal] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [formData, setFormData] = useState(new FormData());

    useEffect(() => {
        const ele = document.querySelector('.progress');
        ele.style.width = `${20 * step}%`;
    }, [step]);

    const handleFormSubmit = () => {
        if(name.length === 0) {
            alert("Please Enter Your Full Name")
        } else if (!/^[A-Za-z\s]+$/.test(name)) {
            alert('Name should only contain letters and spaces.');
        } else if(email.length === 0) {
            alert("Please Enter Your Working Email ID")
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            alert('Invalid email format.');
        } else {

            document.querySelector('.conform-button').style.display="none";
            document.querySelector('.spinner').style.display="block";
            
            axiosInstance.post(`https://${process.env.REACT_APP_END_POINT}/send-otp`,
                {
                    email: email
                }
            )
            .then(response => {
                if (response.status === 200) 
                {
                    const updatedFormData = new FormData();
                    updatedFormData.append("name", name);
                    updatedFormData.append("email", email);
                    updatedFormData.append("service", serviceClass.service);
                    updatedFormData.append("option", optionClass.option);
                    updatedFormData.append("type", typeClass.type);
                    updatedFormData.append("startTime", startClass.start);

                    setFormData(updatedFormData);
                    setEmailModal(!emailModal); 
                    
                    document.querySelector('.spinner').style.display="none";
                    document.querySelector('.conform-button').style.display="block";
                }
            });
        }
    }

    const services = [
        {
            service:'UI/UX Design',
            className: 'uiux'
        },
        {
            service:'Website Development',
            className: 'webdev'
        },
        {
            service:'Mobile App Development',
            className: 'appdev'
        },
        {
            service:'Update Your Website',
            className: 'updateweb'
        },
        {
            service:'Website Testing',
            className: 'webtest'
        },
        {
            service:'UI Automation',
            className: 'uiauto'
        },
    ]
    
    const render_services = services.map((item, index) => {
        return (
            <div key={index} onClick={() => setserviceClass(item)} className={`price-service ${item.className === serviceClass.className ? 'selected' : ''} ${item.className}`}>
                {item.service}
            </div>
        );
    });

    const development_options = [
        {
            option: 'Build Full Product',
            className: 'buildfull'
        }, 
        {
            option: 'Update Product',
            className: 'updateproduct'
        }
    ]

    const render_development_options = development_options.map((item, index) => {
        return (
            <div key={index} onClick={() => setOptionClass(item)} className={`price-service ${item.className === optionClass.className ? 'selected' : ''} ${item.className}`}>
                {item.option}
            </div>
        );
    });

    const platform_type = [
        {
            type: 'Web & Mobile',
            className: 'webandmobile'
        },
        {
            type: 'Web',
            className: 'web'
        },
        {
            type: 'Mobile',
            className: 'mobile'
        },
    ]

    const render_platform_type = platform_type.map((item, index) => {
        return (
            <div key={index} onClick={() => setTypeClass(item)} className={`price-service ${item.className === typeClass.className ? 'selected' : ''} ${item.className}`}>
                {item.type}
            </div>
        );
    });

    const start_type = [
        {
            start: 'Urgent',
            duration: '(As Soon As Possible)',
            className: 'urgent'
        },
        {
            start: 'Standard need',
            duration: '(Withing 1-2 months)',
            className: 'sneed'
        },
        {
            start: 'There is no hurry',
            duration: '(from 2 months)',
            className: 'nohurry'
        },
    ]

    const render_start_type = start_type.map((item, index) => {
        return (
            <div key={index} onClick={() => setStartClass(item)} className={`price-service ${item.className === startClass.className ? 'selected' : ''} ${item.className}`}>
                {item.start}<br />{item.duration}
            </div>
        );
    });

    const render_details_conformation = (
            <div className='details'>
                <div className='fill-details'>
                    <h1>Your Selection</h1>
                    
                    <h4>Selected Service</h4>
                    {serviceClass.service}

                    <h4>What you want to do?</h4>
                    {optionClass.option}

                    <h4>Platform Type?</h4>
                    {typeClass.type} 

                    <h4>When do you want to start?</h4>
                    {startClass.start}
                </div>
                <div className='remaining-details'>
                    <h1>Congratulations! There is only one step left.</h1>
                    <p>Enter Your work email and we'll send you an estimation as soon as possible.</p>
                    <input id ='price-input' value={name} onChange={(event) => setName(event.target.value)} placeholder='Full Name' autoComplete='off'/>
                    <input id ='price-input' value={email} onChange={(event) => setEmail(event.target.value)} placeholder='Email' autoComplete='off'/>
                    <button className='conform-button' onClick={() => handleFormSubmit()}>Get Quotation</button>
                    <Spinner />
                </div>
            </div>
        );
    

    return(
        <div className='price-root'>
            <div className='price-container'>
                { step === 1 ? <h1>Select service</h1> : ''}
                { step === 2 ? <h1>What Whould you like to do?</h1> : ''}
                { step === 3 ? <h1>Platform Type</h1> : ''}
                { step === 4 ? <h1>When Do You Want To Start?</h1> : ''}
                { step === 5 ? <h1>Conform Details</h1> : ''}
                
                <div className='step-bar'>Step({step}/5)</div>
                <div className='progress-bar'>
                    <div className='progress'></div>
                </div>

                <div className='price-content'>
                    { step === 1 ? render_services : ''}
                    { step === 2 ? render_development_options : ''}
                    { step === 3 ? render_platform_type : ''}
                    { step === 4 ? render_start_type : ''}
                    { step === 5 ? render_details_conformation : '' }
                </div>

                <div className='price-footer'>
                    { step !== 5 ? <button className='next-button' onClick={() => setStep(step+1)}>Next</button> : '' }
                    { step !== 1 ? <button className='back-button' onClick={() => setStep(step-1)}>Back</button> : '' }
                </div>

                { emailModal ? <EmailPopUp name={name} email={email} setEmailModal={() => {setEmailModal(!emailModal);}} formData={formData}/> : ''}
            </div>
        </div>	
    );
}
export default Price;
