import { useEffect, useRef } from 'react';

import './TestiMonial.css';

function TestiMonial() {
    const testimonialContentRef = useRef(null);

    useEffect(() => {
        const prevBtn = document.querySelector('.previous-button');
        prevBtn.style.opacity="0";

        const nextBtn = document.querySelector('.nextt-button');
        const testimonialCards = document.querySelectorAll('.testimonial-card');
        const testimonialContent = testimonialContentRef.current;

        let cardWidth = testimonialCards[0].offsetWidth;
        let count = 0;

        const updateCarousel = () => {
            cardWidth = testimonialCards[0].offsetWidth;
            console.log(cardWidth)
            testimonialContent.style.transform = `translateX(${-count * cardWidth - count * 20}px)`;
        };

        const handlePrev = () => {
            if (count > 0) {
                count--;
                updateCarousel();
                nextBtn.style.opacity="1";

                if(count===0) {
                    prevBtn.style.opacity="0";
                }
            }
        };

        const handleNext = () => {
            if (count < testimonialCards.length - 1) {
                count++;
                updateCarousel();
                prevBtn.style.opacity="1";

                if(count === testimonialCards.length - 1) {
                    nextBtn.style.opacity="0";
                }
            }
        };

        prevBtn.addEventListener('click', handlePrev);
        nextBtn.addEventListener('click', handleNext);

        window.addEventListener('resize', updateCarousel);

        return () => {
            prevBtn.removeEventListener('click', handlePrev);
            nextBtn.removeEventListener('click', handleNext);
            window.removeEventListener('resize', updateCarousel);
        };
    }, []);

    return (
        <div className='testimonial'>
            <div className='testimonial-container'>
                <div className='testimonial-header'>
                    <h1>What our clients say about us</h1>
                    <div className='testimonial-scroll'>
                        <h1 className='previous-button'>{'<'}</h1>
                        <h1 className='nextt-button'>{'>'}</h1>
                    </div>
                </div>

                <div className='testimonial-content' ref={testimonialContentRef}>
                    <div className='testimonial-card'>
                        <div className='feedback'><h1>Tejaswi Chaudhary</h1></div>
                        <p>The team exceeded our expectations. They took our vision for a website and turned it into a stunning, user-friendly platform that perfectly represents our brand. Their professionalism, attention to detail, and ability to deliver on time made the entire process seamless.</p>
                    </div>
                    <div className='testimonial-card'>
                        <div className='feedback'><h1>Swati Chaudhary</h1></div>
                        <p>Their creativity and technical expertise brought our project to life in ways we couldn’t have imagined. They not only designed a visually appealing website but also optimized it for performance and user experience. We've seen a significant increase in engagement since the launch!</p>
                    </div>
                    <div className='testimonial-card'>
                        <div className='feedback'><h1>Dewa</h1></div>
                        <p>They listened to our needs, provided excellent advice, and kept us updated every step of the way. Even after the project was completed, their support was exceptional.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TestiMonial;
