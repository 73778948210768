import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: `https://${process.env.REACT_APP_END_POINT}`, // Replace with your backend API's base URL
  timeout: 60000, // Optional: Set a timeout
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add response interceptor
axiosInstance.interceptors.response.use(
  response => {
    console.log(response)

    if (response.status === 200) {
      console.log("OK")
    }
    return response;
  }, // Pass through successful responses

  error => {
    if (error.code === 'ERR_NETWORK') {
      alert('Network error: Please check your connection.');
    } else if (error.response) {
      alert(`Error: ${error.response.status} - ${error.response.data?.message || 'Unknown error'}`);
    } else {
      alert('An unexpected error occurred.');
    }

    //STOP SPINNER
    document.querySelector('.message-us').style.display="block";
    document.querySelector('.spinner').style.display="none";

    return error; // Ensure the error is still propagated
  }
);

export default axiosInstance;
